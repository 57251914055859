.blog-posts-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 60px;
  @media (max-width: $breakpoint-mobile) {
    grid-template-columns: 1fr;
  }
}

.blog-post-card {
  a {
    color: var(--label-color);
  }
  
  img.post-image {
    max-width: 100%; 
    border-radius: 10px;
    margin-bottom: 0.25em;
  }
  
  .category {
    font-size: 0.8em;
    text-transform: uppercase;
    padding-right: 0.5em;
  }
  
  .date {
    padding-left: 0.5em;
    border-left: 1px solid var(--secondary-label-color);
    font-size: 0.8em;
    color: var(--secondary-label-color);
  }
  
  h3 {
    margin-top: 0.5em;
    margin-bottom: 0.25em;
  }
  
  .summary {
    font-size: 0.9em;
  }
}