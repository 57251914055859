.released-version {
  padding: 30px 0;
  
  &:first-of-type {
    margin-top: 2em;
  }
  
  h2 {
    margin-top: 0;
    font-size: 1.4em;
  }

  span.time {
    color: gray;
  }

  h3 {
    margin-top: 1em;
  }

  ul {
    margin: 0;
  }

  li + li {
    margin-top: 0.25em;
  }

  li {
    margin-left: 0.5em;
  }

  img, video {
    max-width: 100%;
    border-radius: 10px;
  }

  /* Desktop Timeline Styles */
  @media (min-width: $breakpoint-mobile + 1) {
    border-left: 2px solid lightgray;

    &:first-of-type {
      padding-top: 0;
    }

    > div {
      position: relative;
      padding-left: 20px;

      &::before {
        border-radius: 50%;
        content: "";
        background-color: var(--text-background-color);
        border: 2px solid lightgray;
        position: absolute;
        height: 14px;
        width: 14px;
        left: -10px;
        top: 6px;
      }
    }

    margin-left: 140px;

    div.release-date {
      position: absolute;
      left: -140px;
      width: 120px;
      text-align: right;
      top: 4px;

      span {
        display: block;
      }

      span.date {
        font-weight: 600;
      }
    }
  }

  @media (max-width: $breakpoint-mobile) {
    border-top: 1px solid gray;
  }
}
