.hero-row.homepage-hero-row {
  h1.hero {
    margin-bottom: 1rem;
  }

  .text-block {
    margin-bottom: 2rem;
  }
  
  .button-container {
    margin: 2em 0; 
    display: flex; 
    flex-wrap: wrap; 
    gap: 8px; 
    justify-content: center;
    align-items: center;
  }

  @media (max-width: $breakpoint-mobile) {
    padding-top: var(--site-margin);
    
    img.window-screenshot {
      border-radius: 6px;
    }
  }
}

#video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; 
  height: 100%; 
  background-color: rgba(0, 0, 0, 0.9); 
  z-index: 99; 
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center;
  
  .icon {
    filter: invert(1);
    width: 24px;
    height: 24px;
    margin: 0 0 5px 0;
  }
  
  > div {
    width: 95vw; 
    text-align: right;
  }
  
  iframe {
    width: 95vw; 
    height: calc(9/16*95vw); 
    max-height: 95vh; 
    margin: 0 auto; 
    display: block;
  }
}

form.waitlist-form {
  text-align: left;
  max-width: 500px;
  margin: 0 auto;
}

.email-input-container {
  display: flex;

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }

  > input[type="email"] {
    flex: 1;
    @media (max-width: $breakpoint-mobile) {
      text-align: center;
    }
  }

  > div:last-child {
    margin-left: 20px;
    display: flex;

    @media (max-width: $breakpoint-mobile) {
      max-width: 300px;
      margin: 10px auto 0 auto;
    }
  }
}

.requirement-label {
  font-size: 14px;
  color: var(--secondary-label-color);
}

img.window-screenshot {
  border-radius: 12px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
  @media (prefers-color-scheme: dark) {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  }
}

section .icon-circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60px;
  margin: 0 auto;
  padding: 15px;
  border-radius: 50px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 1em;
  
  background-color: white;
  @media (prefers-color-scheme: dark) {
    background-color: rgb(55, 55, 55);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }
  
  img {
    max-width: 100%;
  }
}

.accounts-list {
  display: flex;
  
  @media (min-width: $breakpoint-tablet), (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }
  
  gap: 15px;
}

.example-account {
  border-radius: 10px;
  background-color: var(--text-background-color);
  border: 1px solid var(--separator-color);
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  flex-grow: 1;
  overflow: hidden;
}

.scrolling-message-list {
  border-radius: 10px;
  background-color: var(--text-background-color);
  border: 1px solid var(--separator-color);
  padding: 15px;
  margin: 0 -15px;
  margin-bottom: (-1 * $feature-card-padding) - 10px;
  
  hr {
    margin: 15px 0;
    border-color: var(--quinary-label-color);
  }
}

.message-row {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 5px;
  font-size: 0.9em;
  line-height: 1em;
  
  .placeholder-text {
    border-radius: 2px;
    background-color: var(--quinary-label-color);
    height: 1em;
  }
  
  > div:first-child {
    display: flex; 
    justify-content: space-between;
    > div:last-child {
      display: flex;
      gap: 10px;
    }
  }
}

.spinner {
  $spinner-size: 15px;
  $spinner-spacing: 10px;
  visibility: hidden;
  margin: auto #{-1 * ($spinner-size + $spinner-spacing)} auto $spinner-spacing;
  height: $spinner-size;
  width: $spinner-size;
  border: 2px solid lightgray;
  border-top-color: gray;
  border-radius: 50%;
  animation: rotation 0.6s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.logo-list {
  margin-top: 1em;
  display: flex;
  gap: 15px 30px; 
  justify-content: center;
  flex-wrap: wrap;
  
  img {
    height: 50px;
    @media (max-width: $breakpoint-mobile) {
      height: 35px;
    }
  }
}
