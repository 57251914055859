.feature-card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  
  @media (max-width: $breakpoint-mobile) {
    grid-template-columns: 1fr;
  }
}

@media (max-width: $breakpoint-tablet) and (min-width: $breakpoint-mobile+1) {
  .feature-card-grid.tablet-layout {
    grid-template-columns: 1fr 1fr;
    
    .tablet-wide-card {
      order: -1;
      grid-column: 1 / span 2;
    }
  }
}

$feature-card-padding: 30px;

a.feature-card { 
  color: inherit; 
}

.feature-card {
  border-radius: 20px;
  border: 1px solid var(--separator-color);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.4));
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  padding: $feature-card-padding;
  @media (prefers-color-scheme: dark) {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
    background-color: rgb(34, 34, 34);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
  overflow: hidden;
  
  p {
    margin-bottom: 2em;
  }
  
  &.wide-start {
    grid-column: 1 / span 2;
    @media (max-width: $breakpoint-mobile) {
      grid-column: auto;
    }
  }
  &.wide-end {
    grid-column: 2 / span 2;
    @media (max-width: $breakpoint-mobile) {
      grid-column: auto;
    }
  }
  
  &.purple {
    background-color: var(--system-purple-color-light); 
    border-color: var(--system-purple-color-medium);
    h2 { color: var(--system-purple-color); }
  }
  &.mint {
    background-color: var(--system-mint-color-light); 
    border-color: var(--system-mint-color-medium);
    h2 { color: var(--system-mint-color); }
  }
  &.cyan {
    background-color: var(--system-cyan-color-light); 
    border-color: var(--system-cyan-color-medium);
    h2 { color: var(--system-cyan-color); }
  }
  &.blue {
    background-color: var(--system-blue-color-light); 
    border-color: var(--system-blue-color-medium);
    h2 { color: var(--system-blue-color); }
  }
  &.orange {
    background-color: var(--system-orange-color-light); 
    border-color: var(--system-orange-color-medium);
    h2 { color: var(--system-orange-color); }
  }
  &.green {
    background-color: var(--system-green-color-light); 
    border-color: var(--system-green-color-medium);
    h2 { color: var(--system-green-color); }
  }
  &.red {
    background-color: var(--system-red-color-light); 
    border-color: var(--system-red-color-medium);
    h2 { color: var(--system-red-color); }
  }
}

.feature-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;

  @media (max-width: $breakpoint-mobile) {
    grid-template-columns: 1fr;
  }
}

.feature {
  margin: 0 $feature-card-padding;
  position: relative;
  
  @media (max-width: $breakpoint-tablet) and (min-width: $breakpoint-mobile+1) {
    margin-left: 0;
    margin-right: 0;
  }
  
  > img {
    position: absolute;
    left: 0;
    top: 9px;
    
    &.icon {
      max-width: 40px;
      max-height: 40px;
    }
  }
  
  > h2, > h3 {
    margin-left: 40px;
    padding-top: 5px;
  }
  
  > h3 {
    padding-top: 9px;
  }
  
  > p {
    // margin-left: 40px;
  }
}

.coming-soon-tag {
  font-weight: 300;
  font-size: 1rem;
  margin-top: 1rem;
  color: var(--secondary-label-color);
  border: 1px solid var(--secondary-label-color);
  border-radius: 1em;
  padding: 5px 12px;
  display: inline-block;
}

.rounded-tag {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  color: black;
  border-radius: 20px;
  padding: 3px 8px;
  margin-top: -2px;
  
  &.gold-tag {
    background-color: gold;
  }
  &.blue-tag {
    color: white;
    background-color: var(--system-blue-color);
  }
}
