.team-attributes {
  display: flex;
  gap: 2em;
  justify-content: center;
  flex-wrap: wrap;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h3 {
      margin: 0;
    }
  }
}

.contact-type-container {
  align-items: flex-start; 
  gap: 3em;
  
  @media (max-width: $breakpoint-mobile) {
    align-items: center;
  }
}

.contact-type {
  display: flex;
  gap: 1em;
  align-items: flex-start;
  
  @media (max-width: $breakpoint-mobile) {
    width: 100%;
    max-width: 350px;
  }

  h3+p {
    margin-top: 0;
  }
}

.contact-icon-container {
  border-radius: 8px;
  width: 38px;
  height: 38px;
  display: flex;

  img {
    filter: invert(100%);
    width: 24px;
    display: block;
    margin: auto;
  }
}