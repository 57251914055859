$breakpoint-mobile: 750px;
$breakpoint-tablet: 1000px;
$max-site-width: 1200px;
$margin-mobile: 25px;

:root {
  color-scheme: light dark;
  // For Google Chrome
  @media (prefers-color-scheme: dark) {
    color: white;
    background-color: --var(text-background-color);
  }
  
  --site-margin: 75px;
  --content-row-padding: 100px;
  @media (max-width: $breakpoint-tablet) and (min-width: $breakpoint-mobile+1) {
    --site-margin: 40px;
    --content-row-padding: 60px;
  }
  @media (max-width: $breakpoint-mobile) {
    --site-margin: 25px; // keep in sync with $margin-mobile
    --content-row-padding: 50px;
  }
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 1.05rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
}

a {
  color: var(--link-color);
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

h1 {
  line-height: 1;
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: -0.015em;

  &.hero {
    font-size: 3rem;
  }
}

h2 {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

hr {
  border: 0;
  border-top: 1px solid var(--separator-color);
}

hr.narrow {
  width: 175px;
  height: 3px;
  border: none;
  background-color: var(--separator-color);
  border-radius: 2px;
}

code {
  font-family: ui-monospace, monospace;
  padding: 0 5px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.01);
  border: 1px solid var(--grid-color);
  @media (prefers-color-scheme: dark) {
    background-color: rgba(255, 255, 255, 0.01);
  }
}

pre code {
  display: block;
  white-space: pre;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  font-size: 0.75em;
  width: calc(100% - 20px);
  padding: 5px 10px;
}

img.logo {
  @media (prefers-color-scheme: dark) {
    filter: invert(1.0);
  }
}

input {
  font-size: 1rem;
  @media (prefers-color-scheme: dark) {
    // For Google Chrome
    color: white;
  }
}

input:focus {
  outline: none;
}

input[type="email"] {
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid gray;
  padding: 13px 0;
}

.button {
  -webkit-appearance: none;
  appearance: none;
  
  background-color: var(--control-accent-color);
  border: none;
  border-radius: 10px;
  padding: 14px 24px;
  color: white;
  cursor: pointer;
  font-size: 1.0em;
  border: 1px solid transparent;
  
  &.button-secondary {
    color: inherit;
    background-color: inherit;
    border: 1px solid var(--grid-color);
  }
  
  &:disabled {
    background-color: var(--grid-color);
    cursor: inherit;  
  }
}

.page-content {
  max-width: 800px;
  padding: var(--site-margin);
  margin: 0 auto;

  &.main-page {
    text-align: center;
  }
  
  img, video {
    max-width: 100%;
    border-radius: 10px;
  }
}

.narrow-width {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.site-width {
  max-width: 1200px;
  padding: 0 var(--site-margin);
  margin: 0 auto;
}

.centered {
  text-align: center;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }
}

section.row {  
  padding-top: var(--content-row-padding);
  padding-bottom: var(--content-row-padding);
}

section.hero-row {
  @media (min-width: $breakpoint-mobile + 1) {
    padding-top: var(--site-margin);
  }

  picture, img {
    width: 100%;
    max-width: 1202px;
  }
}

section.gray-row {
  border-radius: 30px;
  box-sizing: border-box;
  width: calc(100% - var(--site-margin));
  margin: 0 auto;
  background-color: var(--gray-background-color);
  padding: var(--site-margin);
}