article {
  line-height: 150%;
}

article h2, article h3 {
  margin-top: 1.2em;
}

article > header {
  margin-bottom: 1.5em;
}

article > header .date {
  font-size: 0.8em;
  color: var(--secondary-label-color);
}

article ul > li, article ol > li {
  margin-bottom: 0.5em;
}

article blockquote {
  background-color: var(--system-blue-color-light);
  padding: 10px;
  border-radius: 6px;
  margin: 0;
  border-left: 4px solid var(--system-blue-color);
  font-size: 0.95em;
  
  p {
    margin: 0;
  }
}

.article-header-image {
  max-width: 100%; 
  border-radius: 20px; 
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  
  @media (prefers-color-scheme: dark) {
    box-shadow:  0 1px 5px rgba(0, 0, 0, 0.5);
  }
}

.author-bar {
  margin-top: 1.5em;
  display: flex; 
  justify-content: space-between;
  align-items: center;
  
  > div:last-child {
    text-align: right;
    line-height: 100%;
    font-size: 0.7em;
    color: var(--secondary-label-color);
  }
}

.author {
  display: flex; 
  align-items: center;
  line-height: 120%;
  gap: 10px;
  
  > img {
    box-shadow: 0 0 1px; 
    max-width: 55px; 
    max-height: 55px; 
    border-radius: 55px; 
  }
  
  .author-title {
    font-size: 0.9em;
    color: var(--secondary-label-color);
  }
}

#share-buttons {
  margin-top: 5px;
  display: flex;
  gap: 4px;
  justify-content: flex-end;
  align-items: center;
  
  svg { 
    width: 18px;
    height: 18px;;
    fill: var(--secondary-label-color);
  }
  
  .twitter > svg { 
    height: 20px; 
    margin-top: 1px; 
    margin-right: 3px;
    &:hover {
      fill: #1D9BF0;
    }
  }
  
  .linkedin > svg { 
    height: 19px;
    &:hover {
      fill: #0077b5;
    }
  }
  
  .facebook > svg {
    height: 16px; 
    margin-top: 3px;
    &:hover {
      fill: #1778F2;
    }
  }
  
  .email > svg { 
    height: 14px; 
    margin-top: 1px;
    &:hover {
      fill: #D44638;
    }
  }
}
