div#announcement-banner {
  text-align: center;
  padding: 8px 10px;
  font-size: 0.8em;
  
  color: var(--label-color);
  background-color: var(--announcement-bar-color);
  
  a {
    font-weight: bold;
  }
}

:root {
  /* An approximation of the header height */
  scroll-padding-top: 80px;
}

body > header {
  padding: 8px 0;
  font-size: 0.9em;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  transition: all 0.2s ease;
  border-bottom: 1px solid transparent;
  
  @media (max-width: $breakpoint-mobile) {
    padding: 15px 0;
  }
  
  &.floating {
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-bottom: 1px solid var(--separator-color);
    background-color: rgba(255, 255, 255, 0.72);
    @media (prefers-color-scheme: dark) {
      background-color: rgba(30, 30, 30, 0.72);
    }
  }
  
  > div {
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .mobile-nav-toggle {
    display: none;
  
    @media (max-width: $breakpoint-mobile) {
      display: inherit;
      flex: 0 1 10%;
    }
  }
  
  .icon {
    width: 32px;
    height: 32px;
    vertical-align: bottom;
    filter: none;
  
    @media (prefers-color-scheme: dark) {
      filter: invert(100%);
    }
  }
}

header nav {
  flex: 0 1 67%;
  text-align: right;

  a {
    text-decoration: none;
    color: rgb(70, 70, 70);
    @media (prefers-color-scheme: dark) {
      color: white;
    }
  }

  @media (min-width: $breakpoint-mobile + 1) {
    a.selected, a:hover {
      border-bottom: 2px solid var(--control-accent-color);
      padding-bottom: 10px;
    }
  }

  a + a {
    margin-left: 18px;
  }

  a.mobile-nav-toggle {
    margin-bottom: 40px;
  }
  
  .download-button {
    margin-left: 1em; 
    padding: 10px 20px;
    
    &:hover {
      background-color: var(--system-blue-color);
      color: white;
      border-color: var(--system-blue-color);
    }
  }

  // Sidebar-style mobile navigation
  @media (max-width: $breakpoint-mobile) {
    text-align: left;
    $sidebar-width: 150px;
    $sidebar-shadow-size: 5px;
    position: absolute;
    top: 0;
    left: (-1 * $sidebar-width) - (2 * $margin-mobile) - $sidebar-shadow-size;
    height: 100vh;
    width: $sidebar-width;
    font-size: 1.2em;
    border-right: 1px solid #999;
    padding: var(--site-margin);

    background-color: rgba(255, 255, 255, 0.72);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    z-index: 999;
    transition: 0.2s ease;
    box-shadow: 0 0 $sidebar-shadow-size rgba(0, 0, 0, 0.5);

    @media (prefers-color-scheme: dark) {
      border-right: #333;
      box-shadow: 0 0 $sidebar-shadow-size black;
      background-color: rgba(30, 30, 30, 0.72);
    }

    a {
      display: block;
      margin: 0;
    }

    a + a {
      margin-top: 20px;
      margin-left: 0;
    }
    
    .download-button {
      display: none;
    }
  }
}

body.sidebar-enabled nav {
  left: 0;
}

.logo-container {
  margin: auto 0;
  flex: 0 1 33%;

  @media (max-width: $breakpoint-mobile) {
    flex: 0 1 80%;
    text-align: center;
  }
  
  .app-icon {
    width: 48px;
    height: 48px;
    margin-left: -5px;
    
    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
  }

  a {
    color: inherit;
    font-weight: inherit;
    display: flex;
    align-items: center;
    gap: 10px;
    
    @media (max-width: $breakpoint-mobile) {
      justify-content: center;
    }
  }
}
