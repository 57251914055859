.faqs {
  max-width: 500px;
  margin: 0 auto;
  text-align: left;

  details {
    border-bottom: 1px solid lightgray;
    padding: 0.75rem 0;

    &:last-child {
      border-bottom: none;
    }

    :focus {
      outline: none;
    }

    summary {
      cursor: pointer;
    }

    p {
      color: #777;
      @media (prefers-color-scheme: dark) {
        color: #CCC;
      }
    }
  }
}
