.button > img.icon {
  width: 22px;
  height: 22px;
  vertical-align: middle;
  filter: invert(1.0);
  margin-right: 6px;
  position: relative;
  top: -2px;
}

.button.button-secondary > img.icon {
  filter: invert(0.5);
  @media (prefers-color-scheme: dark) {
    filter: invert(1.0);
  }
}

img.icon {
  width: 48px;
  height: 48px;
  margin: -8px;
  filter: invert(.33) sepia(1) saturate(11) hue-rotate(185deg);

  @media (prefers-color-scheme: dark) {
    filter: invert(0.42) sepia(2) saturate(5) hue-rotate(180deg);
  }
  
  &.large-icon {
    width: 64px;
    height: 64px;
  }
  
  &.system-mint {
    filter: invert(62%) sepia(40%) saturate(4269%) hue-rotate(136deg) brightness(98%) contrast(101%);
    @media (prefers-color-scheme: dark) {
      filter: invert(73%) sepia(98%) saturate(213%) hue-rotate(120deg) brightness(87%) contrast(92%);
    }
  }
  
  &.system-purple {
    filter: invert(49%) sepia(65%) saturate(5025%) hue-rotate(253deg) brightness(93%) contrast(87%);
    @media (prefers-color-scheme: dark) {
      filter: invert(52%) sepia(58%) saturate(5803%) hue-rotate(251deg) brightness(102%) contrast(90%);
    }
  }
  
  &.system-cyan {
    filter: invert(66%) sepia(54%) saturate(688%) hue-rotate(169deg) brightness(98%) contrast(93%);
    @media (prefers-color-scheme: dark) {
      filter: invert(57%) sepia(43%) saturate(617%) hue-rotate(152deg) brightness(99%) contrast(94%);
    }
  }
  
  &.system-blue {
    filter: invert(30%) sepia(75%) saturate(3588%) hue-rotate(202deg) brightness(105%) contrast(103%);
    @media (prefers-color-scheme: dark) {
      filter: invert(42%) sepia(87%) saturate(3654%) hue-rotate(196deg) brightness(101%) contrast(102%);
    }
  }
  
  &.system-orange {
    filter: invert(57%) sepia(91%) saturate(2143%) hue-rotate(2deg) brightness(106%) contrast(103%);
    @media (prefers-color-scheme: dark) {
      filter: invert(75%) sepia(37%) saturate(5884%) hue-rotate(353deg) brightness(107%) contrast(103%);
    }
  }
  
  &.system-red {
    filter: invert(29%) sepia(60%) saturate(2505%) hue-rotate(342deg) brightness(103%) contrast(100%);
    @media (prefers-color-scheme: dark) {
      filter: invert(33%) sepia(41%) saturate(2593%) hue-rotate(336deg) brightness(108%) contrast(101%);
    }
  }
  
  &.system-pink {
    filter: invert(38%) sepia(87%) saturate(5219%) hue-rotate(332deg) brightness(102%) contrast(101%);
    @media (prefers-color-scheme: dark) {
      filter: invert(31%) sepia(81%) saturate(2188%) hue-rotate(328deg) brightness(101%) contrast(101%);
    }
  }
  
  &.system-green {
    filter: invert(69%) sepia(18%) saturate(1919%) hue-rotate(76deg) brightness(90%) contrast(94%);
    @media (prefers-color-scheme: dark) {
      filter: invert(64%) sepia(98%) saturate(407%) hue-rotate(72deg) brightness(91%) contrast(90%);
    }
  }
}