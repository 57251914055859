@media (prefers-color-scheme: dark) {
  .swal-modal {
    background-color: var(--text-background-color);
    color: white;
  }

  .swal-title, .swal-text {
    color: white;
  }

  .swal-icon--success::before, .swal-icon--success::after, .swal-icon--success__hide-corners {
    background-color: var(--text-background-color);
  }
}

.swal-button, .swal-button:not([disabled]):hover {
  background-color: var(--control-accent-color);
}

.swal-button {
  padding: 14px 24px;
  border: 0;
  border-radius: 10px;
}

.swal-button:focus {
  box-shadow: none;
}

.swal-footer {
  text-align: center;
}
