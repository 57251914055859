:root {
    // Colors
    --gray-background-color: rgb(249, 249, 249);
    --announcement-bar-color: rgb(240, 240, 240);
    
    // macOS System Colors
    --text-color: black;
    --text-background-color: white;
    --label-color: rgba(0, 0, 0, 0.85);
    --secondary-label-color: rgba(0, 0, 0, 0.5);
    --tertiary-label-color: rgba(0, 0, 0, 0.25);
    --quaternary-label-color: rgba(0, 0, 0, 0.1);
    --quinary-label-color: rgba(0, 0, 0 , 0.05);
    --grid-color: rgb(204, 204, 204);
    --separator-color: rgb(230, 230, 230);
    --alternating-content-background-color: rgb(245, 245, 245);

    --link-color: rgb(0, 104, 218);
    --control-accent-color: rgb(0, 122, 255); // same for dark
    
    --light-color-opacity: 0.06;
    --medium-color-opacity: 0.14;
    
    --system-red-color: rgb(235, 77, 61);
    --system-red-color-medium: rgba(235, 77, 61, var(--medium-color-opacity));
    --system-red-color-light: rgba(235, 77, 61, var(--light-color-opacity));
    --system-green-color: rgb(100, 202, 86);
    --system-green-color-medium: rgb(100, 202, 86, var(--medium-color-opacity));
    --system-green-color-light: rgb(100, 202, 86, var(--light-color-opacity));
    --system-blue-color: rgb(0, 122, 255);
    --system-blue-color-medium: rgb(0, 122, 255, var(--medium-color-opacity));
    --system-blue-color-light: rgb(0, 122, 255, var(--light-color-opacity));
    --system-orange-color: rgb(240, 154, 56);
    --system-orange-color-medium: rgb(240, 154, 56, var(--medium-color-opacity));
    --system-orange-color-light: rgb(240, 154, 56, var(--light-color-opacity));
    --system-yellow-color: rgb(247, 206, 70);
    --system-yellow-color-medium: rgb(247, 206, 70, var(--medium-color-opacity));
    --system-yellow-color-light: rgb(247, 206, 70, var(--light-color-opacity));
    --system-brown-color: rgb(157, 134, 98);
    --system-brown-color-medium: rgb(157, 134, 98, var(--medium-color-opacity));
    --system-brown-color-light: rgb(157, 134, 98, var(--light-color-opacity));
    --system-pink-color: rgb(235, 68, 90);
    --system-pink-color-medium: rgb(235, 68, 90, var(--medium-color-opacity));
    --system-pink-color-light: rgb(235, 68, 90, var(--light-color-opacity));
    --system-purple-color: rgb(163, 87, 215);
    --system-purple-color-medium: rgb(163, 87, 215, var(--medium-color-opacity));
    --system-purple-color-light: rgb(163, 87, 215, var(--light-color-opacity));
    --system-teal-color: rgb(110, 171, 193);
    --system-teal-color-medium: rgb(110, 171, 193, var(--medium-color-opacity));
    --system-teal-color-light: rgb(110, 171, 193, var(--light-color-opacity));
    --system-indigo-color: rgb(87, 86, 207);
    --system-indigo-color-medium: rgb(87, 86, 207, var(--medium-color-opacity));
    --system-indigo-color-light: rgb(87, 86, 207, var(--light-color-opacity));
    --system-mint-color: rgb(90, 196, 189);
    --system-mint-color-medium: rgb(90, 196, 189, var(--medium-color-opacity));
    --system-mint-color-light: rgb(90, 196, 189, var(--light-color-opacity));
    --system-cyan-color: rgb(112, 188, 235);
    --system-cyan-color-medium: rgb(112, 188, 235, var(--medium-color-opacity));
    --system-cyan-color-light: rgb(112, 188, 235, var(--light-color-opacity));
    --system-gray-color: rgb(142, 142, 147);
    --system-gray-color-medium: rgb(142, 142, 147, var(--medium-color-opacity));
    --system-gray-color-light: rgb(142, 142, 147, var(--light-color-opacity));
    
    @media (prefers-color-scheme: dark) {
        --gray-background-color: rgb(42, 42, 42);
        --announcement-bar-color: rgb(50, 50, 50);
        
        --text-color: white;
        --text-background-color:  rgb(30, 30, 30);
        --label-color: rgba(255, 255, 255, 0.85);
        --secondary-label-color: rgba(255, 255, 255, 0.5);
        --tertiary-label-color: rgba(255, 255, 255, 0.25);
        --quaternary-label-color: rgba(255, 255, 255, 0.1);
        --quinary-label-color: rgba(255, 255, 255 , 0.05);
        --grid-color: rgb(88, 88, 88);
        --separator-color: rgb(51, 52, 55);
        --alternating-content-background-color: rgb(44, 44, 44);
        
        --link-color: rgb(65, 156, 255);
        
        --light-color-opacity: 0.07;
        --medium-color-opacity: 0.08;
        
        --system-red-color: rgb(235, 85, 69);
        --system-red-color-medium: rgb(235, 85, 69, var(--medium-color-opacity));
        --system-red-color-light: rgb(235, 85, 69, var(--light-color-opacity));
        --system-green-color: rgb(106, 212, 95);
        --system-green-color-medium: rgb(106, 212, 95, var(--medium-color-opacity));
        --system-green-color-light: rgb(106, 212, 95, var(--light-color-opacity));
        --system-blue-color: rgb(58, 130, 247);
        --system-blue-color-medium: rgb(58, 130, 247, var(--medium-color-opacity));
        --system-blue-color-light: rgb(58, 130, 247, var(--light-color-opacity));
        --system-orange-color: rgb(242, 164, 60);
        --system-orange-color-medium: rgb(242, 164, 60, var(--medium-color-opacity));
        --system-orange-color-light: rgb(242, 164, 60, var(--light-color-opacity));
        --system-yellow-color: rgb(248, 216, 74);
        --system-yellow-color-medium: rgb(248, 216, 74, var(--medium-color-opacity));
        --system-yellow-color-light: rgb(248, 216, 74, var(--light-color-opacity));
        --system-brown-color: rgb(167, 144, 108);
        --system-brown-color-medium: rgb(167, 144, 108, var(--medium-color-opacity));
        --system-brown-color-light: rgb(167, 144, 108, var(--light-color-opacity));
        --system-pink-color: rgb(235, 75, 99);
        --system-pink-color-medium: rgb(235, 75, 99, var(--medium-color-opacity));
        --system-pink-color-light: rgb(235, 75, 99, var(--light-color-opacity));
        --system-purple-color: rgb(179, 96, 234);
        --system-purple-color-medium: rgb(179, 96, 234, var(--medium-color-opacity));
        --system-purple-color-light: rgb(179, 96, 234, var(--light-color-opacity));
        --system-teal-color: rgb(128, 194, 217);
        --system-teal-color-medium: rgb(128, 194, 217, var(--medium-color-opacity));
        --system-teal-color-light: rgb(128, 194, 217, var(--light-color-opacity));
        --system-indigo-color: rgb(93, 92, 222);
        --system-indigo-color-medium: rgb(93, 92, 222, var(--medium-color-opacity));
        --system-indigo-color-light: rgb(93, 92, 222, var(--light-color-opacity));
        --system-mint-color: rgb(134, 227, 225);
        --system-mint-color-medium: rgb(134, 227, 225, var(--medium-color-opacity));
        --system-mint-color-light: rgb(134, 227, 225, var(--light-color-opacity));
        --system-cyan-color: rgb(119, 197, 241);
        --system-cyan-color-medium: rgb(119, 197, 241, var(--medium-color-opacity));
        --system-cyan-color-light: rgb(119, 197, 241, var(--light-color-opacity));
        --system-gray-color: rgb(152, 152, 157);
        --system-gray-color-medium: rgb(152, 152, 157, var(--medium-color-opacity));
        --system-gray-color-light: rgb(152, 152, 157, var(--light-color-opacity));
    }
}