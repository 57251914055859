footer {
  max-width: $max-site-width;
  width: calc(100% - (2 * var(--site-margin)));
  margin: 0 auto;
  padding: var(--site-margin) 0;
  border-top: 1px solid var(--separator-color);
  
  color: var(--label-color);
  font-size: 0.8em;
  
  display: flex;
  gap: 1em;
  justify-content: space-between;
  vertical-align: center;
  
  @media (max-width: $breakpoint-mobile) {
    flex-direction: column-reverse;
    align-items: center;
    gap: calc(2 * var(--site-margin));
  }
  
  > div {
    @media (max-width: $breakpoint-mobile) {
      text-align: center;
    }
    
    .logo {
      max-width: 175px;
    }
    
    .copyright {
      padding: 0.75em 0;
      line-height: 150%;
      color: var(--secondary-label-color);
      font-size: 0.9em;
    }
    
    .social-icons {
      margin-left: -4px;
      margin-top: 0.5em;
      
      img {
        height: 26px;
        opacity: 0.5;
        
        @media (prefers-color-scheme: dark) {
          filter: invert(100%);
        }
      }
      
      a:hover img {
        opacity: 1;
      }
    }
  }
  
  > nav {
    display: flex;
    gap: 2em;
    @media (max-width: $breakpoint-mobile) {
      gap: 3em;
      flex-wrap: wrap;
    }
    
    > section {
      display: flex;
      flex-direction: column;
    
      h5 {
        font-size: 1em;
        margin-top: 0;
        margin-bottom: 1em;
        font-weight: bold;
      }
      
      a {
        color: var(--text-color);
    
        &:hover {
          text-decoration: underline;
        }
        
        + a {
          margin-top: 1em;
        }
      }
    }
  }
}
