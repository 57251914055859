#billing-toggle-container {
  display: flex; 
  justify-content: center;
}

#billing-toggle {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  
  display: flex;
  padding: 2px;
  font-size: 0.9em;
  gap: 2px;
  
  @media (prefers-color-scheme: dark) {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  > div {
    flex: 0 1 50%;
    padding: 3px 25px;
    cursor: pointer;
  }
  
  > div.selected {
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    @media (prefers-color-scheme: dark) {
      background-color: #333;
    }
  }
}

#coupon-banner {
  margin: 1em 0;
}

.coupon {
  border: 1px dashed var(--system-pink-color);
  border-radius: 6px;
  color: var(--system-pink-color);
  font-size: 0.8em;
  padding: 8px;
  
  &.disabled {
    color: var(--secondary-label-color);
    border-color: var(--secondary-label-color);
  }
}

a.coupon:hover {
  color: white;
  background-color: var(--system-pink-color);
}

.license-cards-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }
}

.license-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  padding: 20px;
  flex: 0 1 50%;
  min-height: 350px;
  
  text-align: left;
  font-size: 0.9em;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--separator-color);
  @media (prefers-color-scheme: dark) {
    background-color: rgb(40, 40, 40);
  }
  
  &.secondary-card {
    background: linear-gradient(rgb(249, 249, 249), rgb(245, 245, 245));
    @media (prefers-color-scheme: dark) {
      background: linear-gradient(rgb(35, 35, 35), rgb(30, 30, 30));
    }
  }
  
  .license-type{
    display: flex;
    align-items: center;
    gap: 15px;
    
    .icon {
      width: 32px;
    }
    
    h3 {
      margin: 0;
    }
  }
  
  .price-amount-original {
    text-decoration: line-through;
    color: var(--system-pink-color);
    
    span {
      color: var(--secondary-label-color);
    }
  }
  
  .price-amount {
    font-size: 2em;
    font-weight: bold;
  }
  
  .whats-included {
    display: flex;
    gap: 10px;
    align-items: center;
    span {
      flex: 1 0 auto;
      font-size: 0.8em;
      font-weight: 600;
      color: var(--system-blue-color);
    }
    div {
      height: 1px;
      width: 100%;
      background-color: var(--separator-color);
    }
  }
  
  button {
    width: 100%;
    
    &.secondary {
      color: var(--text-color);
      border-color: var(--separator-color);
      background-color: var(--text-background-color);
    }
  }
}

.number-stepper {
  display: flex;
  border: 1px solid var(--separator-color);
  border-radius: 6px;
  background-color: var(--text-background-color);
  padding: 0;
  gap: 0;
  overflow: hidden;
    
  button {
    margin: 0;
    padding: 2px 8px;
    vertical-align: middle;
    background-color: transparent;
    line-height: 1.4;
    color: inherit;
    border: 0;
    font-size: 1.0rem;
    font-family: sans-serif;
    &:hover {
      background-color: var(--alternating-content-background-color);
      cursor: pointer;
    }
    -webkit-user-select: none;
    user-select: none;
  }
  
  button:first-child {
    border-right: 1px solid var(--separator-color);
  }
  button:last-child {
    border-left: 1px solid var(--separator-color);
  }
  
  > input[type=number] {
    -moz-appearance: textfield;
    text-align: center;
    border: none;
    font-size: 1.0em;
    width: 50px;
    
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}

ul.checkmark-list {
  flex: 0 1 50%;
  text-align: left;
  font-size: 0.9em;
  list-style-type: '\2713\0020';
  padding: 0 1em;
  li {
    margin-bottom: 0.5em;
  }
  li::marker {
    content: '\2713\0020';
    color: green;
    font-weight: 500;
  }
}

div#confirmation-summary {
  display: inline-block; 
  text-align: left; 
  padding: 15px 50px; 
  border-radius: 20px;
  border: 1px solid var(--separator-color);
  
  table {
    margin: 0 auto;
  }
  
  table tr td:first-child {
    text-align: right;
    font-weight: bold;
    padding-right: 6px;
  }
}
